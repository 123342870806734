import { combineReducers } from 'redux';

// Import other reducers
import testReducer from './test-reducer';
import SearchReducer from './search-reducer';

// Combine all reducers
const rootReducer = combineReducers({
  testReducer, SearchReducer,
});

export default rootReducer;