const initialState = {
  page: 1,
  searchData: {
    data: [],
    total: 0,
    pages: 0,
  },
  searchLoading: true,
  searchFailed: false,
  searchTerms: '',
};

const SearchReducer = (state = initialState, action) => {
  // Create dispatch actions here
  switch (action.type) {

    case 'SET_SEARCH_DATA':
      return { ...state,
        searchData: {
          data: action.data,
          total: action.total,
          pages: action.pages,
        }
    };

    case 'SET_SEARCH_LOADING':
      return { ...state,
        searchLoading: action.loading,
      };

    case 'SET_SEARCH_FAILURE':
      return { ...state,
        searchFailed: action.failed,
      };

    case 'SET_SEARCH_TERMS':
      return { ...state,
        searchTerms: action.searchTerms,
      };

    case 'SET_PAGE':
      return { ...state,
        page: action.page,
      };

    default:
      return state;
  }
};

export default SearchReducer;